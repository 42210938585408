/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'version',
  initialState: {
    codeVersion: null
  },
  reducers: {
    setCodeVersion(state, { payload }) {
      state.codeVersion = payload;
    }
  }
});

export default slice.reducer;

export const getCodeVersion = state => state;
export const { setCodeVersion } = slice.actions;

import React, { useMemo } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { makeid } from 'core/utils';
import { PropTypes } from 'prop-types';

const MoreTextWithToolTip = ({
  children = '',
  className = '',
  innerClassName = '',
  lines = 1,
  tip = '',
  onClick,
  style = {},
  icons = [],
  autohide = true
}) => {
  const id = useMemo(() => `tip${makeid(8)}`, []);
  return (
    <div className={className} style={{ width: '100%', display: 'flex' }}>
      <div
        id={id}
        className={innerClassName}
        style={
          lines > 1
            ? {
                overflowWrap: 'anywhere',
                whiteSpace: 'pre-wrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: `${lines}`,
                WebkitBoxOrient: 'vertical',
                ...style
              }
            : {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                ...style
              }
        }
        onClick={onClick}
      >
        {children}
      </div>
      {icons &&
        icons.length > 0 &&
        icons.map(icon => (
          <i key={icon} className="material-icons text-muted m-l-10">
            {icon}
          </i>
        ))}
      <UncontrolledTooltip
        placement={style.tooltipPlacement ? style.tooltipPlacement : 'auto'}
        autohide={autohide}
        target={id}
      >
        {tip ? tip : children}
      </UncontrolledTooltip>
    </div>
  );
};

MoreTextWithToolTip.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  icons: PropTypes.array,
  innerClassName: PropTypes.string,
  lines: PropTypes.number,
  maxWidth: PropTypes.number,
  tip: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func
};

export default MoreTextWithToolTip;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { UncontrolledTooltip } from 'reactstrap';
import { randomString } from 'core/helpers';
import i18n from 'i18n';

class LabelTooltip extends Component {
  static propTypes = {
    text: PropTypes.string,
    tip: PropTypes.string,
    labelClass: PropTypes.string,
    wrapperClass: PropTypes.string,
    helpMarkup: PropTypes.string,
    t: PropTypes.any
  };

  constructor(props) {
    super(props);
    this.state = {
      id: 'tips_' + randomString(10)
    };
  }

  handleClick = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  render() {
    const { text, tip, wrapperClass, labelClass, helpMarkup } = this.props;
    const { id } = this.state;
    const wrapperClassName = typeof wrapperClass === 'undefined' ? 'inline' : wrapperClass;
    const labelClassName = typeof labelClass === 'undefined' ? 'inline' : labelClass;
    return (
      <div className={wrapperClassName}>
        <label className={labelClassName}>
          <span style={{ flex: '1' }}>{text} </span>
          <i className="material-icons md-18 text-muted m-l-5" id={id} onClick={this.handleClick}>
            help_outline
          </i>
          {helpMarkup === 'required' && <div className="help help-ast">{i18n.t('bot_settings.required')}</div>}
          {helpMarkup === 'maxLength' && <div className="help">{i18n.t('bot_settings.maxLength')}</div>}
        </label>
        <UncontrolledTooltip placement="auto" target={id}>
          {tip}
        </UncontrolledTooltip>
      </div>
    );
  }
}

export default LabelTooltip;

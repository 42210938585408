import axios from 'axios';
const baseURL = process.env.REACT_APP_TCI_IAM_API_URL;
const headers = { 'x-api-key': process.env.REACT_APP_API_KEY };
const getCodeVersion = async () => {
  const result = await axios({
    method: 'get',
    url: `${baseURL}/app/version/code`,
    headers
  });
  return result?.data;
};

export default {
  getCodeVersion
};

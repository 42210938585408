import { connect } from 'react-redux';
import view from 'pages/UnlockRequest/view';
import { updateFullLoading, selectFullLoading } from 'store/loadingSlice';
import { getModeLogin, getLoginIdStore, setLoginIdStore } from 'store/globalSlice';

const mapState = state => {
  return {
    fullloading: selectFullLoading(state),
    modeLogin: getModeLogin(state),
    loginIdStore: getLoginIdStore(state)
  };
};

const mapDispatch = { updateFullLoading, setLoginIdStore };

export default connect(mapState, mapDispatch)(view);

import { connect } from 'react-redux';
import view from 'pages/Section/view';
import { updateFullLoading, selectFullLoading } from 'store/loadingSlice';
import { updateNotification } from 'store/notificationSlice';
import { updateUser, getUser } from 'store/userSlice';
import { getResource, updateResourceModal } from 'store/resourceSlice';
import { addSection } from 'store/sectionSlice';

const mapState = state => {
  return {
    fullloading: selectFullLoading(state),
    resource: getResource(state),
    userInfo: getUser(state)
  };
};

const mapDispatch = {
  updateFullLoading: updateFullLoading,
  updateResourceModal: updateResourceModal,
  updateNotification,
  updateUser,
  addSection
};

export default connect(mapState, mapDispatch)(view);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-switch';
import { pick } from 'lodash';

export default class BaseSwitch extends PureComponent {
  static propTypes = {
    name: PropTypes.string,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string
  };
  static defaultProps = {
    checked: false,
    disabled: false,
    onChange: () => {}
  };

  onChange(value) {
    this.props.onChange(value);
  }

  render() {
    const switchProps = {
      ...pick(this.props, ['name', 'checked', 'disabled', 'uncheckedIcon', 'checkedIcon', 'className'])
    };
    return (
      <Switch
        {...switchProps}
        offColor="#888888"
        onColor="#2979FF"
        uncheckedIcon={switchProps.uncheckedIcon ? switchProps.uncheckedIcon : false}
        checkedIcon={switchProps.checkedIcon ? switchProps.checkedIcon : false}
        onChange={this.onChange.bind(this)}
      />
    );
  }
}

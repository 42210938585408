import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class TextCounter extends Component {
  static propTypes = {
    data: PropTypes.any,
    wrapclass: PropTypes.any,
    max: PropTypes.number
  };

  render() {
    const { data, max, wrapClass } = this.props;
    return (
      <label className={`inline m-b-0 lh-18 ${wrapClass}`}>
        {data.length > 0 && (
          <span className="text-muted fs-11">
            <span className={data.length >= max ? 'text-danger' : ''}>{data.length}</span> / {max}
          </span>
        )}
      </label>
    );
  }
}

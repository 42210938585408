export const EMOJI_REGEX = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
export const COOKIE_KEY_APP_ATM_TOKEN = 'app_atm_token';
export const COOKIE_KEY_APP_ATM_TOKEN_EXP = 'app_atm_token_exp';
export const COOKIE_KEY_USER_TOKEN = 'userToken';
export const COOKIE_KEY_REFRESH_TOKEN = 'rtk';
export const regexpPassword = /^[a-zA-Z0-9~`!@#$%^&*()_+\-=[\]{}\\|?,.<>/'";:]*$/;

// Use to sync multi tab when login/logout
export const STORAGE_KEY_APP_SYNC_SIGNED_IN = 'app_sync_signed_in';
export const STORAGE_KEY_LAST_ACTIVE_TIME = 'lat';
export const DOMAIN_TRANSCOSMOS = 'trans-cosmos.co.jp';

//
export const mobilePhoneLength = 25;
export const maxLengthPassword = 256;

export const SERVICE_PROVIDER = {
  AWS_MNG_CONSOLE: 'aws_mng_console',
  WIKI: 'wiki',
  LYSITHEA_PC: 'lysithea_pc',
  LYSITHEA_SP: 'lysithea_sp',
  TIME_SHEET: 'time_sheet',
  SAML_CUSTOM: 'saml_custom',
  MICROSOFT_365_AZURE: 'microsoft_365_azure'
};

export const MODE_LOGIN = {
  LOGIN_BY_EMAIL: 'login',
  LOGIN_BY_LOGIN_ID: 'loginById'
};

export const ACCEPTED_DIAL_CODES = {
  '+81': 'JP'
  // '+65': 'SG',
  // '+62': 'ID',
  // '+66': 'TH',
  // '+60': 'MY',
  // '+84': 'VN',
  // '+63': 'PH',
  // '+886': 'TW'
};
export const MOBILE_PHONE_MAX_LENGTH_BY_COUNTRY = {
  JP: 11
  // SG: 8,
  // ID: 12,
  // TH: 10,
  // MY: 11,
  // VN: 10,
  // PH: 11,
  // TW: 10
};

export const AUTHENTICATOR_APP_NAME = 't-passport';
export const EMAIL_MAX_LENGTH = 111;
export const USER_ACCOUNT_LOGIN_BY_ID_MAX_LENGTH = 60;
export const DOMAIN_NAME_MAX_LENGTH = 50;

export const delegatedProfileTypes = {
  SHARED_LOGIN_ID: 'LOGIN_ID',
  SHARED_EMAIL_ALIAS: 'EMAIL_ALIAS',
  SHARED_LYSITHEAID_ID: 'LYSITHEAID_ID'
};

export const COUNTRY_CODES = {
  // AF: { countryName: 'Afghanistan', dialCode: '+93' },
  // AX: { countryName: 'Aland Islands', dialCode: '+358' },
  // AL: { countryName: 'Albania', dialCode: '+355' },
  // DZ: { countryName: 'Algeria', dialCode: '+213' },
  // AS: { countryName: 'AmericanSamoa', dialCode: '+1684' },
  // AD: { countryName: 'Andorra', dialCode: '+376' },
  // AO: { countryName: 'Angola', dialCode: '+244' },
  // AI: { countryName: 'Anguilla', dialCode: '+1264' },
  // AQ: { countryName: 'Antarctica', dialCode: '+672' },
  // AG: { countryName: 'Antigua and Barbuda', dialCode: '+1268' },
  // AR: { countryName: 'Argentina', dialCode: '+54' },
  // AM: { countryName: 'Armenia', dialCode: '+374' },
  // AW: { countryName: 'Aruba', dialCode: '+297' },
  // AU: { countryName: 'Australia', dialCode: '+61' },
  // AT: { countryName: 'Austria', dialCode: '+43' },
  // AZ: { countryName: 'Azerbaijan', dialCode: '+994' },
  // BS: { countryName: 'Bahamas', dialCode: '+1242' },
  // BH: { countryName: 'Bahrain', dialCode: '+973' },
  // BD: { countryName: 'Bangladesh', dialCode: '+880' },
  // BB: { countryName: 'Barbados', dialCode: '+1246' },
  // BY: { countryName: 'Belarus', dialCode: '+375' },
  // BE: { countryName: 'Belgium', dialCode: '+32' },
  // BZ: { countryName: 'Belize', dialCode: '+501' },
  // BJ: { countryName: 'Benin', dialCode: '+229' },
  // BM: { countryName: 'Bermuda', dialCode: '+1441' },
  // BT: { countryName: 'Bhutan', dialCode: '+975' },
  // BO: { countryName: 'Bolivia, Plurinational State of', dialCode: '+591' },
  // BA: { countryName: 'Bosnia and Herzegovina', dialCode: '+387' },
  // BW: { countryName: 'Botswana', dialCode: '+267' },
  // BR: { countryName: 'Brazil', dialCode: '+55' },
  // IO: { countryName: 'British Indian Ocean Territory', dialCode: '+246' },
  // BN: { countryName: 'Brunei Darussalam', dialCode: '+673' },
  // BG: { countryName: 'Bulgaria', dialCode: '+359' },
  // BF: { countryName: 'Burkina Faso', dialCode: '+226' },
  // BI: { countryName: 'Burundi', dialCode: '+257' },
  // KH: { countryName: 'Cambodia', dialCode: '+855' },
  // CM: { countryName: 'Cameroon', dialCode: '+237' },
  // CA: { countryName: 'Canada', dialCode: '+1' },
  // CV: { countryName: 'Cape Verde', dialCode: '+238' },
  // KY: { countryName: 'Cayman Islands', dialCode: '+ 345' },
  // CF: { countryName: 'Central African Republic', dialCode: '+236' },
  // TD: { countryName: 'Chad', dialCode: '+235' },
  // CL: { countryName: 'Chile', dialCode: '+56' },
  // CN: { countryName: 'China', dialCode: '+86' },
  // CX: { countryName: 'Christmas Island', dialCode: '+61' },
  // CC: { countryName: 'Cocos (Keeling) Islands', dialCode: '+61' },
  // CO: { countryName: 'Colombia', dialCode: '+57' },
  // KM: { countryName: 'Comoros', dialCode: '+269' },
  // CG: { countryName: 'Congo', dialCode: '+242' },
  // CD: { countryName: 'Congo, The Democratic Republic of the Congo', dialCode: '+243' },
  // CK: { countryName: 'Cook Islands', dialCode: '+682' },
  // CR: { countryName: 'Costa Rica', dialCode: '+506' },
  // CI: { countryName: "Cote d'Ivoire", dialCode: '+225' },
  // HR: { countryName: 'Croatia', dialCode: '+385' },
  // CU: { countryName: 'Cuba', dialCode: '+53' },
  // CY: { countryName: 'Cyprus', dialCode: '+357' },
  // CZ: { countryName: 'Czech Republic', dialCode: '+420' },
  // DK: { countryName: 'Denmark', dialCode: '+45' },
  // DJ: { countryName: 'Djibouti', dialCode: '+253' },
  // DM: { countryName: 'Dominica', dialCode: '+1767' },
  // DO: { countryName: 'Dominican Republic', dialCode: '+1849' },
  // EC: { countryName: 'Ecuador', dialCode: '+593' },
  // EG: { countryName: 'Egypt', dialCode: '+20' },
  // SV: { countryName: 'El Salvador', dialCode: '+503' },
  // GQ: { countryName: 'Equatorial Guinea', dialCode: '+240' },
  // ER: { countryName: 'Eritrea', dialCode: '+291' },
  // EE: { countryName: 'Estonia', dialCode: '+372' },
  // ET: { countryName: 'Ethiopia', dialCode: '+251' },
  // FK: { countryName: 'Falkland Islands (Malvinas)', dialCode: '+500' },
  // FO: { countryName: 'Faroe Islands', dialCode: '+298' },
  // FJ: { countryName: 'Fiji', dialCode: '+679' },
  // FI: { countryName: 'Finland', dialCode: '+358' },
  // FR: { countryName: 'France', dialCode: '+33' },
  // GF: { countryName: 'French Guiana', dialCode: '+594' },
  // PF: { countryName: 'French Polynesia', dialCode: '+689' },
  // GA: { countryName: 'Gabon', dialCode: '+241' },
  // GM: { countryName: 'Gambia', dialCode: '+220' },
  // GE: { countryName: 'Georgia', dialCode: '+995' },
  // DE: { countryName: 'Germany', dialCode: '+49' },
  // GH: { countryName: 'Ghana', dialCode: '+233' },
  // GI: { countryName: 'Gibraltar', dialCode: '+350' },
  // GR: { countryName: 'Greece', dialCode: '+30' },
  // GL: { countryName: 'Greenland', dialCode: '+299' },
  // GD: { countryName: 'Grenada', dialCode: '+1473' },
  // GP: { countryName: 'Guadeloupe', dialCode: '+590' },
  // GU: { countryName: 'Guam', dialCode: '+1671' },
  // GT: { countryName: 'Guatemala', dialCode: '+502' },
  // GG: { countryName: 'Guernsey', dialCode: '+44' },
  // GN: { countryName: 'Guinea', dialCode: '+224' },
  // GW: { countryName: 'Guinea-Bissau', dialCode: '+245' },
  // GY: { countryName: 'Guyana', dialCode: '+595' },
  // HT: { countryName: 'Haiti', dialCode: '+509' },
  // VA: { countryName: 'Holy See (Vatican City State)', dialCode: '+379' },
  // HN: { countryName: 'Honduras', dialCode: '+504' },
  // HK: { countryName: 'Hong Kong', dialCode: '+852' },
  // HU: { countryName: 'Hungary', dialCode: '+36' },
  // IS: { countryName: 'Iceland', dialCode: '+354' },
  // IN: { countryName: 'India', dialCode: '+91' },
  JP: { countryName: 'Japan', dialCode: '+81' }
  // SG: { countryName: 'Singapore', dialCode: '+65' },
  // ID: { countryName: 'Indonesia', dialCode: '+62' },
  // TH: { countryName: 'Thailand', dialCode: '+66' },
  // MY: { countryName: 'Malaysia', dialCode: '+60' },
  // VN: { countryName: 'Vietnam', dialCode: '+84' },
  // PH: { countryName: 'Philippines', dialCode: '+63' },
  // TW: { countryName: 'Taiwan', dialCode: '+886' }

  // IR: { countryName: 'Iran, Islamic Republic of Persian Gulf', dialCode: '+98' },
  // IQ: { countryName: 'Iraq', dialCode: '+964' },
  // IE: { countryName: 'Ireland', dialCode: '+353' },
  // IM: { countryName: 'Isle of Man', dialCode: '+44' },
  // IL: { countryName: 'Israel', dialCode: '+972' },
  // IT: { countryName: 'Italy', dialCode: '+39' },
  // JM: { countryName: 'Jamaica', dialCode: '+1876' },
  // JE: { countryName: 'Jersey', dialCode: '+44' },
  // JO: { countryName: 'Jordan', dialCode: '+962' },
  // KZ: { countryName: 'Kazakhstan', dialCode: '+77' },
  // KE: { countryName: 'Kenya', dialCode: '+254' },
  // KI: { countryName: 'Kiribati', dialCode: '+686' },
  // KP: { countryName: "Korea, Democratic People's Republic of Korea", dialCode: '+850' },
  // KR: { countryName: 'Korea, Republic of South Korea', dialCode: '+82' },
  // KW: { countryName: 'Kuwait', dialCode: '+965' },
  // KG: { countryName: 'Kyrgyzstan', dialCode: '+996' },
  // LA: { countryName: 'Laos', dialCode: '+856' },
  // LV: { countryName: 'Latvia', dialCode: '+371' },
  // LB: { countryName: 'Lebanon', dialCode: '+961' },
  // LS: { countryName: 'Lesotho', dialCode: '+266' },
  // LR: { countryName: 'Liberia', dialCode: '+231' },
  // LY: { countryName: 'Libyan Arab Jamahiriya', dialCode: '+218' },
  // LI: { countryName: 'Liechtenstein', dialCode: '+423' },
  // LT: { countryName: 'Lithuania', dialCode: '+370' },
  // LU: { countryName: 'Luxembourg', dialCode: '+352' },
  // MO: { countryName: 'Macao', dialCode: '+853' },
  // MK: { countryName: 'Macedonia', dialCode: '+389' },
  // MG: { countryName: 'Madagascar', dialCode: '+261' },
  // MW: { countryName: 'Malawi', dialCode: '+265' },
  // MV: { countryName: 'Maldives', dialCode: '+960' },
  // ML: { countryName: 'Mali', dialCode: '+223' },
  // MT: { countryName: 'Malta', dialCode: '+356' },
  // MH: { countryName: 'Marshall Islands', dialCode: '+692' },
  // MQ: { countryName: 'Martinique', dialCode: '+596' },
  // MR: { countryName: 'Mauritania', dialCode: '+222' },
  // MU: { countryName: 'Mauritius', dialCode: '+230' },
  // YT: { countryName: 'Mayotte', dialCode: '+262' },
  // MX: { countryName: 'Mexico', dialCode: '+52' },
  // FM: { countryName: 'Micronesia, Federated States of Micronesia', dialCode: '+691' },
  // MD: { countryName: 'Moldova', dialCode: '+373' },
  // MC: { countryName: 'Monaco', dialCode: '+377' },
  // MN: { countryName: 'Mongolia', dialCode: '+976' },
  // ME: { countryName: 'Montenegro', dialCode: '+382' },
  // MS: { countryName: 'Montserrat', dialCode: '+1664' },
  // MA: { countryName: 'Morocco', dialCode: '+212' },
  // MZ: { countryName: 'Mozambique', dialCode: '+258' },
  // MM: { countryName: 'Myanmar', dialCode: '+95' },
  // NA: { countryName: 'Namibia', dialCode: '+264' },
  // NR: { countryName: 'Nauru', dialCode: '+674' },
  // NP: { countryName: 'Nepal', dialCode: '+977' },
  // NL: { countryName: 'Netherlands', dialCode: '+31' },
  // AN: { countryName: 'Netherlands Antilles', dialCode: '+599' },
  // NC: { countryName: 'New Caledonia', dialCode: '+687' },
  // NZ: { countryName: 'New Zealand', dialCode: '+64' },
  // NI: { countryName: 'Nicaragua', dialCode: '+505' },
  // NE: { countryName: 'Niger', dialCode: '+227' },
  // NG: { countryName: 'Nigeria', dialCode: '+234' },
  // NU: { countryName: 'Niue', dialCode: '+683' },
  // NF: { countryName: 'Norfolk Island', dialCode: '+672' },
  // MP: { countryName: 'Northern Mariana Islands', dialCode: '+1670' },
  // NO: { countryName: 'Norway', dialCode: '+47' },
  // OM: { countryName: 'Oman', dialCode: '+968' },
  // PK: { countryName: 'Pakistan', dialCode: '+92' },
  // PW: { countryName: 'Palau', dialCode: '+680' },
  // PS: { countryName: 'Palestinian Territory, Occupied', dialCode: '+970' },
  // PA: { countryName: 'Panama', dialCode: '+507' },
  // PG: { countryName: 'Papua New Guinea', dialCode: '+675' },
  // PY: { countryName: 'Paraguay', dialCode: '+595' },
  // PE: { countryName: 'Peru', dialCode: '+51' },
  // PN: { countryName: 'Pitcairn', dialCode: '+872' },
  // PL: { countryName: 'Poland', dialCode: '+48' },
  // PT: { countryName: 'Portugal', dialCode: '+351' },
  // PR: { countryName: 'Puerto Rico', dialCode: '+1939' },
  // QA: { countryName: 'Qatar', dialCode: '+974' },
  // RO: { countryName: 'Romania', dialCode: '+40' },
  // RU: { countryName: 'Russia', dialCode: '+7' },
  // RW: { countryName: 'Rwanda', dialCode: '+250' },
  // RE: { countryName: 'Reunion', dialCode: '+262' },
  // BL: { countryName: 'Saint Barthelemy', dialCode: '+590' },
  // SH: { countryName: 'Saint Helena, Ascension and Tristan Da Cunha', dialCode: '+290' },
  // KN: { countryName: 'Saint Kitts and Nevis', dialCode: '+1869' },
  // LC: { countryName: 'Saint Lucia', dialCode: '+1758' },
  // MF: { countryName: 'Saint Martin', dialCode: '+590' },
  // PM: { countryName: 'Saint Pierre and Miquelon', dialCode: '+508' },
  // VC: { countryName: 'Saint Vincent and the Grenadines', dialCode: '+1784' },
  // WS: { countryName: 'Samoa', dialCode: '+685' },
  // SM: { countryName: 'San Marino', dialCode: '+378' },
  // ST: { countryName: 'Sao Tome and Principe', dialCode: '+239' },
  // SA: { countryName: 'Saudi Arabia', dialCode: '+966' },
  // SN: { countryName: 'Senegal', dialCode: '+221' },
  // RS: { countryName: 'Serbia', dialCode: '+381' },
  // SC: { countryName: 'Seychelles', dialCode: '+248' },
  // SL: { countryName: 'Sierra Leone', dialCode: '+232' },
  // SK: { countryName: 'Slovakia', dialCode: '+421' },
  // SI: { countryName: 'Slovenia', dialCode: '+386' },
  // SB: { countryName: 'Solomon Islands', dialCode: '+677' },
  // SO: { countryName: 'Somalia', dialCode: '+252' },
  // ZA: { countryName: 'South Africa', dialCode: '+27' },
  // SS: { countryName: 'South Sudan', dialCode: '+211' },
  // GS: { countryName: 'South Georgia and the South Sandwich Islands', dialCode: '+500' },
  // ES: { countryName: 'Spain', dialCode: '+34' },
  // LK: { countryName: 'Sri Lanka', dialCode: '+94' },
  // SD: { countryName: 'Sudan', dialCode: '+249' },
  // SR: { countryName: 'Suriname', dialCode: '+597' },
  // SJ: { countryName: 'Svalbard and Jan Mayen', dialCode: '+47' },
  // SZ: { countryName: 'Swaziland', dialCode: '+268' },
  // SE: { countryName: 'Sweden', dialCode: '+46' },
  // CH: { countryName: 'Switzerland', dialCode: '+41' },
  // SY: { countryName: 'Syrian Arab Republic', dialCode: '+963' },
  // TJ: { countryName: 'Tajikistan', dialCode: '+992' },
  // TZ: { countryName: 'Tanzania, United Republic of Tanzania', dialCode: '+255' },
  // TL: { countryName: 'Timor-Leste', dialCode: '+670' },
  // TG: { countryName: 'Togo', dialCode: '+228' },
  // TK: { countryName: 'Tokelau', dialCode: '+690' },
  // TO: { countryName: 'Tonga', dialCode: '+676' },
  // TT: { countryName: 'Trinidad and Tobago', dialCode: '+1868' },
  // TN: { countryName: 'Tunisia', dialCode: '+216' },
  // TR: { countryName: 'Turkey', dialCode: '+90' },
  // TM: { countryName: 'Turkmenistan', dialCode: '+993' },
  // TC: { countryName: 'Turks and Caicos Islands', dialCode: '+1649' },
  // TV: { countryName: 'Tuvalu', dialCode: '+688' },
  // UG: { countryName: 'Uganda', dialCode: '+256' },
  // UA: { countryName: 'Ukraine', dialCode: '+380' },
  // AE: { countryName: 'United Arab Emirates', dialCode: '+971' },
  // GB: { countryName: 'United Kingdom', dialCode: '+44' },
  // US: { countryName: 'United States', dialCode: '+1' },
  // UY: { countryName: 'Uruguay', dialCode: '+598' },
  // UZ: { countryName: 'Uzbekistan', dialCode: '+998' },
  // VU: { countryName: 'Vanuatu', dialCode: '+678' },
  // VE: { countryName: 'Venezuela, Bolivarian Republic of Venezuela', dialCode: '+58' },
  // VG: { countryName: 'Virgin Islands, British', dialCode: '+1284' },
  // VI: { countryName: 'Virgin Islands, U.S.', dialCode: '+1340' },
  // WF: { countryName: 'Wallis and Futuna', dialCode: '+681' },
  // YE: { countryName: 'Yemen', dialCode: '+967' },
  // ZM: { countryName: 'Zambia', dialCode: '+260' },
  // ZW: { countryName: 'Zimbabwe', dialCode: '+263' }
};

export const MFA_METHODS = {
  EMAIL: 'EMAIL',
  SMS: 'SMS',
  AUTHENTICATOR: 'AUTHENTICATOR'
};

export const PROVIDER = {
  GOOGLE: 'google',
  EMAIL_PASSWORD: 'email',
  ACTIVE_DIRECTORY: 'ad',
  SAML: 'saml',
  MICROSOFT: 'microsoft'
};

import { connect } from 'react-redux';
import { updateNotification, selectNotification } from 'store/notificationSlice';
import { updateFullLoading, selectFullLoading } from 'store/loadingSlice';
import { updateUser, getUser } from 'store/userSlice';
import { setLoginIdStore } from 'store/globalSlice';
import { addResources } from 'store/resourceSlice';
import view from './view';

const mapState = state => {
  return {
    notification: { ...selectNotification(state) },
    fullloading: selectFullLoading(state),
    currentUser: getUser(state)
  };
};

const mapDispatch = {
  updateNotification: updateNotification,
  updateFullLoading: updateFullLoading,
  updateUser: updateUser,
  setLoginIdStore,
  addResources
};

export default connect(mapState, mapDispatch)(view);

import { connect } from 'react-redux';
import view from 'pages/MFA/view';
import { updateFullLoading, selectFullLoading } from 'store/loadingSlice';
import { getModeLogin, getLoginIdStore, setModeLogin, setLoginIdStore } from 'store/globalSlice';
import { updateUser } from 'store/userSlice';
import { updateNotification, selectNotification } from 'store/notificationSlice';

const mapState = state => {
  return {
    fullloading: selectFullLoading(state),
    modeLogin: getModeLogin(state),
    loginIdStore: getLoginIdStore(state),
    notification: { ...selectNotification(state) }
  };
};

const mapDispatch = {
  updateFullLoading: updateFullLoading,
  setModeLogin,
  setLoginIdStore,
  updateUser,
  updateNotification
};

export default connect(mapState, mapDispatch)(view);

import { request } from 'core/request';

const getLogs = async () => {
  const data = await request({
    method: 'get',
    url: 'dashboard/users/logs'
  });
  return data;
};

export default {
  getLogs
};

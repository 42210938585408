import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import AvailableService from './AvailableService';
import { useTranslation } from 'react-i18next';

function Introduction(props) {
  const { t } = useTranslation();

  const [view, setView] = useState('availableServices');

  return (
    <div>
      <h3>{t('common.label.introduction').toUpperCase()}</h3>
      <Nav tabs className="nav-tabs-simple">
        {
          <NavItem>
            <NavLink
              className={classnames({ active: view === 'availableServices' })}
              onClick={() => {
                setView('availableServices');
              }}
            >
              {t('introduction.availableServices')}
            </NavLink>
          </NavItem>
        }
      </Nav>
      <TabContent activeTab={view} className="bg-white">
        {
          <TabPane tabId="availableServices">
            <Row>
              <Col sm="12">
                <AvailableService t={t} view={view} />
              </Col>
            </Row>
          </TabPane>
        }
      </TabContent>
    </div>
  );
}

export default Introduction;

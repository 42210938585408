/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'section',
  initialState: {
    sections: []
  },
  reducers: {
    addSection(state, { payload: sections }) {
      state.sections = sections;
    }
  }
});

export default slice.reducer;

export const getSections = state => state.section.sections;
export const { addSection } = slice.actions;

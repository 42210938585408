import { connect } from 'react-redux';
import view from './view';
import { updateNotification, selectNotification } from 'store/notificationSlice';
import { updateUser, getUser } from 'store/userSlice';
import { updateFullLoading, selectFullLoading } from 'store/loadingSlice';
import { addResources, updateResourceModal, getResource } from 'store/resourceSlice';
import { getSections, addSection } from 'store/sectionSlice';
import { getModeLogin, setLoginIdStore, setModeLogin } from 'store/globalSlice';

const mapState = state => {
  return {
    currentUser: getUser(state),
    sections: getSections(state),
    notification: { ...selectNotification(state) },
    fullloading: selectFullLoading(state),
    resource: getResource(state),
    modeLogin: getModeLogin(state)
  };
};

const mapDispatch = {
  updateNotification,
  updateUser,
  updateFullLoading,
  addResources,
  updateResourceModal,
  addSection,
  setLoginIdStore,
  setModeLogin
};

export default connect(mapState, mapDispatch)(view);

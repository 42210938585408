import { connect } from 'react-redux';
import view from 'pages/ProfileSetting/view';
import { selectNotification } from 'store/notificationSlice';
import { selectFullLoading } from 'store/loadingSlice';
import { getUser } from 'store/userSlice';

const mapState = state => {
  return {
    currentUser: getUser(state),
    notification: { ...selectNotification(state) },
    fullloading: selectFullLoading(state)
  };
};

const mapDispatch = {};

export default connect(mapState, mapDispatch)(view);

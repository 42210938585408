import React from 'react';
import { getEnvName } from 'core/helpers';
import { useTranslation } from 'react-i18next';
import { setCodeVersion } from 'store/versionSlice';
import { version as versionService } from 'services';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const Copyright = props => {
  const { t } = useTranslation();
  const dispath = useDispatch();
  const codeVersion = useSelector(state => state.version.codeVersion);

  useEffect(() => {
    (async () => {
      if (!codeVersion) {
        const fetchVersion = await versionService.getCodeVersion();
        dispath(setCodeVersion(fetchVersion));
      }
    })();
  }, []);

  const getCopyrightByEnv = () => {
    let productionVersion = '';
    // get production version for ticket TPPAS-13249
    if (codeVersion && codeVersion.gitBranch && codeVersion.gitBranch.includes('-v')) {
      productionVersion = codeVersion.gitBranch.split('-v')[1];
      if (productionVersion.includes('-')) {
        productionVersion = productionVersion.split('-')[0];
      }
    }

    return (
      codeVersion && (
        <span
          dangerouslySetInnerHTML={
            ['prod', 'production'].includes(codeVersion.env)
              ? {
                  __html: t('Footer.Version.Production', {
                    version: productionVersion,
                    commitID: codeVersion.gitCommitHash
                  })
                }
              : {
                  __html: t('Footer.Version.NonProduction', {
                    version: getEnvName(codeVersion.env),
                    branchName: codeVersion.gitBranch,
                    commitID: codeVersion.gitCommitHash
                  })
                }
          }
        />
      )
    );
  };
  const { className } = props;
  return (
    <p className={className}>
      <span className="hint-text">
        {getCopyrightByEnv()}
        <span
          dangerouslySetInnerHTML={{
            __html: t('Footer.Information', { year: new Date().getFullYear() })
          }}
        />
      </span>
    </p>
  );
};

export default Copyright;

import { createSlice } from '@reduxjs/toolkit';
import { MODE_LOGIN } from 'core/constants';

const slice = createSlice({
  name: 'global',
  initialState: {
    modeLogin: localStorage.getItem('modeLogin'),
    loginIdStore: null
  },
  reducers: {
    setModeLogin(state, { payload: modeLogin }) {
      state.modeLogin = modeLogin;
      localStorage.setItem('modeLogin', modeLogin);
    },
    setLoginIdStore(state, { payload: loginIdStore }) {
      state.loginIdStore = loginIdStore;
      if (loginIdStore) localStorage.setItem('loginIdStore', loginIdStore);
      else localStorage.removeItem('loginIdStore');
    }
  }
});
export const { setModeLogin, setLoginIdStore } = slice.actions;
export default slice.reducer;

export const getModeLogin = state => state.global.modeLogin;
export const getLoginIdStore = state => state.global.loginIdStore;

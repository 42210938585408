import React, { useState } from 'react';
import 'assets/scss/theme/login.scss';
import BaseInputPassword from 'components/BaseInputPassword';
import BaseLabelCounter from 'components/BaseLabelCounter';
import { Form, FormGroup, FormFeedback } from 'reactstrap';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { logger } from 'core/logger';
import { user as userService } from 'services';
import { errorUtils } from 'core/utils';
import { useHistory } from 'react-router';
import { cookies, resetLocalStorage } from 'core/utils';
import { MODE_LOGIN, maxLengthPassword } from 'core/constants';

function Password(props) {
  const { t } = useTranslation();
  const { updateNotification, updateFullLoading, updateUser, modeLogin } = props;
  const history = useHistory();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errOldPassword, setErrOldPassword] = useState('');
  const [errNewPassword, setErrNewPassword] = useState('');
  const [errConfirmPassword, setErrConfirmPassword] = useState('');

  const onOldPasswordChange = value => {
    let err = '';
    if (!value) {
      err = 'common.validator.isRequired';
    }
    setOldPassword(value);
    setErrOldPassword(err);
    return !err;
  };

  const onConfirmPasswordChange = value => {
    let err = '';
    if (!value) {
      err = 'common.validator.isRequired';
    } else if (value !== newPassword) {
      err = 'changePassword.message.password.notMatch';
    }
    setConfirmPassword(value);
    setErrConfirmPassword(err);
    return !err;
  };

  const onNewPasswordChange = (value, errorMessage) => {
    let err = '';
    if (!value) {
      err = 'common.validator.isRequired';
    } else if (errorMessage) {
      err = errorMessage;
    }
    setNewPassword(value);
    setErrNewPassword(err);
    if (errConfirmPassword !== '' && confirmPassword === value) {
      setErrConfirmPassword('');
    } else if (confirmPassword !== value) {
      setErrConfirmPassword('changePassword.message.password.notMatch');
    }
    return !err && !errorMessage;
  };

  const validateAll = () => {
    const validOldPassword = onOldPasswordChange(oldPassword);
    const validNewPassword = onNewPasswordChange(newPassword, errNewPassword);
    const validConfirmPassword = onConfirmPasswordChange(confirmPassword);
    return validOldPassword && validNewPassword && validConfirmPassword;
  };

  const handleChangePassword = async e => {
    e.preventDefault();
    try {
      updateFullLoading(true);
      const validData = validateAll();
      if (!validData) {
        toast.warning(t('common.message.invalidInputs'));
        return;
      }
      await userService.changePassword(window.btoa(oldPassword), window.btoa(newPassword), 'LOGIN');
      toast.success(t('changePassword.message.changed.ok'));
      setTimeout(() => {
        resetLocalStorage();
        cookies.removeAllTokens();
        updateUser(null);
        history.push('/tci');
      }, 2000);
    } catch (err) {
      logger.error('handleChangePassword -> err', err);
      const { response } = err;
      const { data = {} } = response || {};
      let code = data.code;
      if (err.error) {
        code = err.error;
      }
      switch (code) {
        case 'password-wrong-format':
          toast.warning(t('changePassword.message.password.wrongFormat'));
          break;
        case 'old-password-required':
          toast.warning(t('common.validator.isRequired', { label: t('changePassword.newPassword.label') }));
          break;
        case 'new-password-required':
          toast.warning(t('common.validator.isRequired', { label: t('changePassword.newPassword.label') }));
          break;
        case 'newPassword-must-different-oldPassword':
          toast.warning(t('changePassword.message.newPass.mustBeDifferent.oldPass'));
          break;
        case 'wrong-password':
          toast.warning(t('changePassword.message.old.password.incorrect'));
          break;
        case 'password-exist':
          toast.warning(t('changePassword.message.cannot.use.recent.password'));
          break;
        case 'user-not-found':
          toast.warning(t('common.message.user.notFound'));
          break;
        default:
          errorUtils.handleError(err, toast, updateNotification, updateUser, history, t);
      }
    } finally {
      updateFullLoading(false);
    }
  };

  const resetForm = () => {
    setOldPassword('');
    setNewPassword('');
    setConfirmPassword('');
    setErrConfirmPassword('');
    setErrNewPassword('');
    setErrOldPassword('');
  };

  return (
    <div className="row justify-content-center">
      <Form method="post" className="col-12 col-lg-9" action="#" autoComplete="off" onSubmit={handleChangePassword}>
        <FormGroup className="form-group-default">
          <BaseLabelCounter label={t('changePassword.oldPassword.label')} />
          <BaseInputPassword
            name="oldPassword"
            showStrength={false}
            showEyeIcon={false}
            placeholder={t('placeholder.input.Enter', {
              field: t('changePassword.oldPassword.label')
            })}
            value={oldPassword}
            onChange={onOldPasswordChange}
          />
        </FormGroup>
        <FormFeedback className="d-block">
          {errOldPassword ? t(errOldPassword, { label: t('changePassword.oldPassword.label') }) : ''}
        </FormFeedback>
        <FormGroup className="form-group-default">
          <BaseLabelCounter
            label={t('changePassword.newPassword.label')}
            value={newPassword}
            length={maxLengthPassword}
          />
          <BaseInputPassword
            name="newPassword"
            placeholder={t('placeholder.input.Enter', {
              field: t('changePassword.newPassword.label')
            })}
            maxLength={maxLengthPassword}
            value={newPassword}
            onChange={onNewPasswordChange}
          />
        </FormGroup>
        <FormFeedback className="d-block">
          {errNewPassword ? t(errNewPassword, { label: t('changePassword.newPassword.label') }) : ''}
        </FormFeedback>

        <FormGroup className="form-group-default">
          <BaseLabelCounter label={t('changePassword.confirmPassword.label')} />
          <BaseInputPassword
            name="confirmPassword"
            showStrength={false}
            showEyeIcon={false}
            placeholder={t('placeholder.input.Enter', {
              field: t('changePassword.confirmPassword.label')
            })}
            value={confirmPassword}
            onChange={onConfirmPasswordChange}
          />
        </FormGroup>
        <FormFeedback className="d-block">
          {errConfirmPassword ? t(errConfirmPassword, { label: t('changePassword.confirmPassword.label') }) : ''}
        </FormFeedback>
        <div className="d-flex">
          <button className="btn btn-primary m-r-15" type="submit">
            {t('changePassword.button.changePassword')}
          </button>
          <button className="btn btn-default" type="button" onClick={resetForm}>
            {t('common.button.reset')}
          </button>
        </div>
      </Form>
    </div>
  );
}
export default Password;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import BaseTable from 'components/BaseTable';
import MoreTextWithToolTip from 'components/MoreTextWithToolTip';

const AvailableService = ({ t, view }) => {
  const description = t('introduction.availableServices.description');
  const [pageSize, setPageSize] = useState(100);

  const introductionItemList = [
    {
      id: 1,
      category: {
        data: t('introduction.available.services.category.internal.system'),
        className: 'border-bottom-none text-font-customize'
        // className: 'border-top-bold border-right-bold border-bottom-none border-left-bold',
      },
      services: {
        data: t('introduction.available.services.services.lysithea'),
        className: 'border-top-bold border-right-bold border-bottom-bold'
      },
      description: {
        data: t('introduction.available.services.description.lysithea'),
        className: 'border-top-bold border-right-bold border-bottom-bold'
      },
      columnCustomize: 'category'
    },
    {
      id: 2,
      category: {
        data: t('introduction.available.services.category.internal.system'),
        className: 'border-bottom-none',
        // className: 'border-right-bold border-bottom-none border-left-bold',
        hiddenValue: true
      },
      services: {
        data: t('introduction.available.services.services.tcipf.guidelines'),
        className: 'border-right-bold border-bottom-bold'
      },
      description: {
        data: t('introduction.available.services.description.tcipf.guidelines'),
        className: 'border-right-bold border-bottom-bold'
      },
      columnCustomize: 'category'
    },
    {
      id: 3,
      category: {
        data: t('introduction.available.services.category.internal.system'),
        className: 'border-bottom-none',
        // className: 'border-right-bold border-bottom-none border-left-bold',
        hiddenValue: true
      },
      services: {
        data: t('introduction.available.services.services.id.management.system'),
        className: 'border-right-bold border-bottom-bold'
      },
      description: {
        data: t('introduction.available.services.description.id.management.system'),
        className: 'border-right-bold border-bottom-bold'
      },
      columnCustomize: 'category'
    },
    {
      id: 4,
      category: {
        data: t('introduction.available.services.category.internal.system'),
        className: 'border-bottom-none',
        // className: 'border-right-bold border-bottom-none border-left-bold',
        hiddenValue: true
      },
      services: {
        data: t('introduction.available.services.services.tintelligate'),
        className: 'border-right-bold border-bottom-bold'
      },
      description: {
        data: t('introduction.available.services.description.tintelligate'),
        className: 'border-right-bold border-bottom-bold'
      },
      columnCustomize: 'category'
    },
    {
      id: 5,
      category: {
        data: t('introduction.available.services.category.internal.system'),
        className: 'border-bottom-none',
        // className: 'border-right-bold border-bottom-none border-left-bold',
        hiddenValue: true
      },
      services: {
        data: t('introduction.available.services.services.concur'),
        className: 'border-right-bold border-bottom-bold'
      },
      description: {
        data: t('introduction.available.services.description.concur'),
        className: 'border-right-bold border-bottom-bold'
      },
      columnCustomize: 'category'
    },
    {
      id: 6,
      category: {
        data: t('introduction.available.services.category.internal.system'),
        className: 'border-bottom-none',
        // className: 'border-right-bold border-bottom-bold border-left-bold',
        hiddenValue: true
      },
      services: {
        data: t('introduction.available.services.services.amazon.business'),
        className: 'border-right-bold border-bottom-bold'
      },
      description: {
        data: t('introduction.available.services.description.amazon.business'),
        className: 'border-right-bold border-bottom-bold'
      }
      // columnCustomize: 'category',
    },
    {
      id: 7,
      category: {
        data: t('introduction.available.services.category.cloud.system'),
        className: 'border-bottom-none text-font-customize'
        // className: "border-right-bold border-bottom-none border-left-bold",
      },
      services: {
        data: t('introduction.available.services.services.sonarQube'),
        className: 'border-right-bold border-bottom-bold'
      },
      description: {
        data: t('introduction.available.services.description.sonarQube'),
        className: 'border-right-bold border-bottom-bold'
      },
      columnCustomize: 'category'
    },
    {
      id: 8,
      category: {
        data: t('introduction.available.services.category.cloud.system'),
        className: 'border-bottom-none',
        // className: "border-right-bold border-bottom-none border-left-bold",
        hiddenValue: true
      },
      services: {
        data: t('introduction.available.services.services.amazon.quickSight'),
        className: 'border-right-bold border-bottom-bold'
      },
      description: {
        data: t('introduction.available.services.description.amazon.quickSight'),
        className: 'border-right-bold border-bottom-bold'
      },
      columnCustomize: 'category'
    },
    {
      id: 9,
      category: {
        data: t('introduction.available.services.category.cloud.system'),
        className: 'border-bottom-none',
        // className: "border-right-bold border-bottom-none border-left-bold",
        hiddenValue: true
      },
      services: {
        data: t('introduction.available.services.services.google.workspace'),
        className: 'border-right-bold border-bottom-bold'
      },
      description: {
        data: t('introduction.available.services.description.google.workspace'),
        className: 'border-right-bold border-bottom-bold'
      },
      columnCustomize: 'category'
    },
    {
      id: 10,
      category: {
        data: t('introduction.available.services.category.cloud.system'),
        className: 'border-bottom-none',
        // className: "border-right-bold border-bottom-none border-left-bold",
        hiddenValue: true
      },
      services: {
        data: t('introduction.available.services.services.microsoft.azure.ms365'),
        className: 'border-right-bold border-bottom-bold'
      },
      description: {
        data: t('introduction.available.services.description.microsoft.azure.ms365'),
        className: 'border-right-bold border-bottom-bold'
      },
      columnCustomize: 'category'
    },
    {
      id: 11,
      category: {
        data: t('introduction.available.services.category.cloud.system'),
        className: 'border-bottom-none',
        // className: "border-right-bold border-bottom-none border-left-bold",
        hiddenValue: true
      },
      services: {
        data: t('introduction.available.services.services.sumo.logic'),
        className: 'border-right-bold border-bottom-bold'
      },
      description: {
        data: t('introduction.available.services.description.sumo.logic'),
        className: 'border-right-bold border-bottom-bold'
      },
      columnCustomize: 'category'
    },
    {
      id: 12,
      category: {
        data: t('introduction.available.services.category.cloud.system'),
        className: 'border-bottom-none',
        // className: "border-right-bold border-bottom-none border-left-bold",
        hiddenValue: true
      },
      services: {
        data: t('introduction.available.services.services.ui.path'),
        className: 'border-right-bold border-bottom-bold'
      },
      description: {
        data: t('introduction.available.services.description.ui.path'),
        className: 'border-right-bold border-bottom-bold'
      },
      columnCustomize: 'category'
    },
    {
      id: 13,
      category: {
        data: t('introduction.available.services.category.cloud.system'),
        className: 'border-bottom-none',
        // className: "border-right-bold border-bottom-none border-left-bold",
        hiddenValue: true
      },
      services: {
        data: t('introduction.available.services.services.snowflake'),
        className: 'border-right-bold border-bottom-bold'
      },
      description: {
        data: t('introduction.available.services.description.snowflake'),
        className: 'border-right-bold border-bottom-bold'
      },
      columnCustomize: 'category'
    },
    {
      id: 14,
      category: {
        data: t('introduction.available.services.category.cloud.system'),
        className: 'border-bottom-none',
        // className: "border-right-bold border-bottom-none border-left-bold",
        hiddenValue: true
      },
      services: {
        data: t('introduction.available.services.services.click.up'),
        className: 'border-right-bold border-bottom-bold'
      },
      description: {
        data: t('introduction.available.services.description.click.up'),
        className: 'border-right-bold border-bottom-bold'
      },
      columnCustomize: 'category'
    },
    {
      id: 15,
      category: {
        data: t('introduction.available.services.category.cloud.system'),
        className: 'border-bottom-none',
        // className: "border-right-bold border-bottom-none border-left-bold",
        hiddenValue: true
      },
      services: {
        data: t('introduction.available.services.services.notion'),
        className: 'border-right-bold border-bottom-bold'
      },
      description: {
        data: t('introduction.available.services.description.notion'),
        className: 'border-right-bold border-bottom-bold'
      },
      columnCustomize: 'category'
    },
    {
      id: 16,
      category: {
        data: t('introduction.available.services.category.cloud.system'),
        className: 'border-bottom-none',
        // className: "border-right-bold border-bottom-none border-left-bold",
        hiddenValue: true
      },
      services: {
        data: t('introduction.available.services.services.kintone'),
        className: 'border-right-bold border-bottom-bold'
      },
      description: {
        data: t('introduction.available.services.description.kintone'),
        className: 'border-right-bold border-bottom-bold'
      },
      columnCustomize: 'category'
    },
    {
      id: 17,
      category: {
        data: t('introduction.available.services.category.cloud.system'),
        className: 'border-bottom-none',
        // className: "border-right-bold border-bottom-none border-left-bold",
        hiddenValue: true
      },
      services: {
        data: t('introduction.available.services.services.slack'),
        className: 'border-right-bold border-bottom-bold'
      },
      description: {
        data: t('introduction.available.services.description.slack'),
        className: 'border-right-bold border-bottom-bold'
      },
      columnCustomize: 'category'
    },
    {
      id: 18,
      category: {
        data: t('introduction.available.services.category.cloud.system'),
        className: 'border-bottom-none',
        // className: "border-right-bold border-bottom-none border-left-bold",
        hiddenValue: true
      },
      services: {
        data: t('introduction.available.services.services.dbt'),
        className: 'border-right-bold border-bottom-bold'
      },
      description: {
        data: t('introduction.available.services.description.dbt'),
        className: 'border-right-bold border-bottom-bold'
      },
      columnCustomize: 'category'
    },
    {
      id: 19,
      category: {
        data: t('introduction.available.services.category.cloud.system'),
        className: 'border-bottom-none',
        // className: "border-right-bold border-bottom-none border-left-bold",
        hiddenValue: true
      },
      services: {
        data: t('introduction.available.services.services.data.robot'),
        className: 'border-right-bold border-bottom-bold'
      },
      description: {
        data: t('introduction.available.services.description.data.robot'),
        className: 'border-right-bold border-bottom-bold'
      },
      columnCustomize: 'category'
    },
    {
      id: 20,
      category: {
        data: t('introduction.available.services.category.cloud.system'),
        className: 'border-bottom-none',
        // className: "border-right-bold border-bottom-bold border-left-bold",
        hiddenValue: true
      },
      services: {
        data: t('introduction.available.services.services.kaonabi'),
        className: 'border-right-bold border-bottom-bold'
      },
      description: {
        data: t('introduction.available.services.description.kaonabi'),
        className: 'border-right-bold border-bottom-bold'
      },
      columnCustomize: 'category'
    }
  ];

  const _renderColumValue = (row, type) => {
    let value = row.value;

    if (row.value.data) {
      value = row.value.data;
      if (row.value.hiddenValue) {
        value = '';
      }
    }
    return <MoreTextWithToolTip>{value}</MoreTextWithToolTip>;
  };

  const getColumns = React.useMemo(() => {
    return [
      {
        Header: t('introduction.available.services.header.category'),
        accessor: 'category',
        maxWidth: 200,
        minWidth: 200,
        sortable: false,
        Cell: row => _renderColumValue(row)
      },
      {
        Header: t('introduction.available.services.header.services'),
        accessor: 'services',
        maxWidth: 280,
        minWidth: 280,
        sortable: false,
        Cell: row => _renderColumValue(row)
      },
      {
        Header: t('introduction.available.services.header.description'),
        minWidth: 320,
        accessor: 'description',
        sortable: false,
        Cell: row => _renderColumValue(row)
      }
    ];
  });

  return (
    <div>
      <div className="pl-1 pr-1" dangerouslySetInnerHTML={{ __html: description }} />
      {/* <Card className={'mt-4'}> */}
      {/* <CardHeader className="pb-0">{t('label.attached.policy.items')}</CardHeader> */}
      {/* <CardBody> */}
      <Row className={'mt-4 mb-4'}>
        <Col style={{ maxWidth: '76rem' }}>
          <BaseTable
            columns={getColumns}
            data={introductionItemList}
            sortedRules={[
              {
                id: 'id',
                desc: false
              }
            ]}
            pageSize={pageSize}
            showPagination={false}
          />
        </Col>
      </Row>
      {/* </CardBody> */}
      {/* </Card> */}
    </div>
  );
};
AvailableService.propTypes = {
  t: PropTypes.func,
  view: PropTypes.string
};

export default AvailableService;

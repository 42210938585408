/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'user',
  initialState: {
    user: null
  },
  reducers: {
    updateUser(state, { payload: user }) {
      state.user = user;
    }
  }
});

export const { updateUser } = slice.actions;
export default slice.reducer;

export const getUser = state => state.user.user;

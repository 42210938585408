import { connect } from 'react-redux';
import view from 'pages/ProfileSetting/forms/Password/view';
import { updateNotification, selectNotification } from 'store/notificationSlice';
import { updateFullLoading, selectFullLoading } from 'store/loadingSlice';
import { updateUser } from 'store/userSlice';
import { getModeLogin } from 'store/globalSlice';

const mapState = state => {
  return {
    notification: { ...selectNotification(state) },
    fullloading: selectFullLoading(state),
    modeLogin: getModeLogin(state)
  };
};

const mapDispatch = {
  updateNotification: updateNotification,
  updateFullLoading: updateFullLoading,
  updateUser: updateUser
};

export default connect(mapState, mapDispatch)(view);

import moment from 'moment';
import momentTz from 'moment-timezone';

const defaultFormat = {
  dateMinute: 'YYYY-MM-DD HH:mm \\[UTCZ\\]',
  dateSecond: 'YYYY-MM-DD HH:mm:ss \\[UTCZ\\]',
  dateSecondTimezone: timezone => 'YYYY-MM-DD HH:mm:ss ' + timezone
};

export default {
  dateMinute: dateStr => moment(dateStr).format(defaultFormat.dateMinute),
  dateSecond: (dateStr, timezone = Intl.DateTimeFormat().resolvedOptions().timeZone) =>
    momentTz(dateStr)
      .tz(timezone)
      .format(defaultFormat.dateSecond)
};

const arrayToObject = array => {
  let resultMap = {};
  if (array && array.length > 0) {
    resultMap = array.reduce((c, item) => {
      const { id } = item;
      c[id] = item;
      return c;
    }, {});
  }
  return resultMap;
};

export default {
  arrayToObject
};

import React, { Component, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import 'assets/scss/theme/termsOfUse.scss';
import { checkIsMobile } from 'core/helpers';
import { FormGroup, Input } from 'reactstrap';
import { useHistory } from 'react-router';
import { Loading } from 'element-react';
import i18n from '../../i18n';

class Paragraph extends Component {
  static propTypes = { content: PropTypes.string };
  static defaultProps = { content: '' };
  render = () => <p className={''} dangerouslySetInnerHTML={{ __html: this.props.content }} />;
}

function TermsOfUse(props) {
  const { updateFullLoading, fullloading } = props;
  const { t } = useTranslation();
  let lang = localStorage.getItem('sysLanguage') || 'en_US';
  const [checked, setChecked] = useState(false);

  const history = useHistory();
  let isMobile = checkIsMobile();
  // if (isMobile) lang = detectLanguageMobile(lang);
  const onChangeCheckAgree = value => {
    setChecked(value);
  };
  const onAgreeTerm = () => {
    if (!window.flutter_inappwebview) return;
    updateFullLoading(true);
    setTimeout(() => {
      window.flutter_inappwebview.callHandler('agreeTerms', true).then(() => {
        updateFullLoading(false);
        history.push('/tci');
      });
    }, 3000);
  };

  useEffect(() => {
    if (isMobile) {
      const content = document.querySelector('.mobile-content');
      let isScrolling;

      const handleScroll = () => {
        content.classList.remove('hide-scrollbar');
        window.clearTimeout(isScrolling);

        isScrolling = setTimeout(() => {
          content.classList.add('hide-scrollbar');
        }, 500);
      };

      content.addEventListener('scroll', handleScroll);

      // Initially hide the scrollbar
      content.classList.add('hide-scrollbar');

      return () => {
        content.removeEventListener('scroll', handleScroll);
      };
    }
  }, [isMobile]);

  return (
    <div className="terms_of_use-wrapper">
      <div className="container">
        <div className="row">
          <h2 className={isMobile ? 'mobile-title' : 'title-page'}>
            {isMobile ? t('termsOfUse.mobile.title') : t('termsOfUse.title')}
          </h2>
          {isMobile ? (
            <div className="mobile-content">
              <Paragraph content={t('termsOfUse.mobile.content', { value: 'End.' })} />
            </div>
          ) : (
            <div className={`content content_${lang}`}>
              <Paragraph content={t('termsOfUse.content.intro.part.1') + t('termsOfUse.content.intro.part.2')} />
              <Paragraph
                content={
                  t('termsOfUse.content.article.1.part.1') +
                  t('termsOfUse.content.article.1.part.2') +
                  t('termsOfUse.content.article.1.part.3')
                }
              />
              <Paragraph
                content={t('termsOfUse.content.article.2.part.1') + t('termsOfUse.content.article.2.part.2')}
              />
              <Paragraph
                content={
                  t('termsOfUse.content.article.3.part.1') +
                  t('termsOfUse.content.article.3.part.2') +
                  t('termsOfUse.content.article.3.part.3') +
                  t('termsOfUse.content.article.3.part.4') +
                  t('termsOfUse.content.article.3.part.5')
                }
              />
              <Paragraph content={t('termsOfUse.content.article.4')} />
              <Paragraph
                content={
                  t('termsOfUse.content.article.5.part.1') +
                  t('termsOfUse.content.article.5.part.2') +
                  t('termsOfUse.content.article.5.part.3')
                }
              />
              <Paragraph content={t('termsOfUse.content.article.6')} />
              <Paragraph content={t('termsOfUse.content.article.7')} />
              <Paragraph content={t('termsOfUse.content.article.8')} />
              <Paragraph content={t('termsOfUse.content.established')} />
            </div>
          )}
        </div>
        {isMobile && (
          <div className="btn-agree-terms">
            <FormGroup check id="checkbox-active">
              <Input type="checkbox" checked={checked} onChange={e => onChangeCheckAgree(e.target.checked)} />
              <button className="btn btn-primary btn-cons m-t-12 btn-block" disabled={!checked} onClick={onAgreeTerm}>
                {t('termsOfUse.mobile.button.label')}
              </button>
            </FormGroup>
          </div>
        )}
      </div>
      {fullloading && <Loading fullscreen={true} />}
    </div>
  );
}

function detectLanguageMobile(currentLanguage) {
  const queryString = new URLSearchParams(window.location.search);
  const query = new URLSearchParams(queryString);
  const mobileLanguage = query.get('language') || 'en_US';
  if (mobileLanguage !== currentLanguage) {
    localStorage.setItem('sysLanguage', mobileLanguage);
    i18n.changeLanguage(mobileLanguage);
    return mobileLanguage;
  }
}

export default TermsOfUse;

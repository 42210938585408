import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import BaseSelect from 'components/BaseSelect';
import timeZoneData from './timezones';
import './index.scss';

class TimeZoneSelect extends PureComponent {
  static propTypes = {
    value: PropTypes.string,
    options: PropTypes.array,
    offset: PropTypes.oneOf(['GMT', 'UTC']),
    onChange: PropTypes.func,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    clearable: PropTypes.bool
  };

  static defaultProps = {
    value: '',
    clearable: true,
    offset: 'UTC'
  };

  stringifyZone(zone, offset) {
    const ensure2Digits = num => (num > 9 ? `${num}` : `0${num}`);
    return `(${offset}${zone.offset < 0 ? '-' : '+'}${ensure2Digits(Math.floor(Math.abs(zone.offset)))}:${ensure2Digits(
      Math.abs((zone.offset % 1) * 60)
    )}) ${zone.label}`;
  }

  // TODO comment
  handleChange = value => {
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  };

  render() {
    const { offset, placeholder, value } = this.props;
    const timeZoneOptions = timeZoneData.map(tm => {
      return {
        value: tm.name,
        label: this.stringifyZone(tm, offset)
      };
    });
    return (
      <div className={this.props.className}>
        <BaseSelect
          value={value}
          options={timeZoneOptions}
          placeholder={placeholder}
          onChange={this.handleChange.bind(this)}
          clearable={this.props.clearable}
          disabled={this.props.disabled}
          multiple={false}
          sortable={false}
          allowSort={false}
        />
      </div>
    );
  }
}
export default TimeZoneSelect;

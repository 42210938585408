import { connect } from 'react-redux';

import { selectFullLoading } from 'store/loadingSlice';
import { updateFullLoading } from 'store/loadingSlice';
import view from './view';

const mapState = state => {
  return {
    fullloading: selectFullLoading(state)
  };
};

const mapDispatch = {
  updateFullLoading: updateFullLoading
};

export default connect(mapState, mapDispatch)(view);

/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'resource',
  initialState: {
    resources: {
      initialized: false,
      data: []
    },
    showModal: false,
    sectionId: 'add',
    resourceIds: [],
    sectionName: ''
  },
  reducers: {
    addResources(state, { payload }) {
      state.resources = payload;
    },
    updateResourceModal(state, { payload }) {
      const { showModal = false, sectionId = 'add', resourceIds = [], sectionName = '' } = payload;
      state.showModal = showModal;
      state.sectionId = sectionId;
      state.resourceIds = resourceIds;
      state.sectionName = sectionName;
    }
  }
});

export const { addResources, updateResourceModal } = slice.actions;
export default slice.reducer;

export const getResource = state => state.resource;

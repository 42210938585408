import React from 'react';
import PropTypes from 'prop-types';
import { ulid } from 'ulid';
import { FormGroup, Label, Input, Col, Row } from 'reactstrap';

const RadioList = ({
  options,
  key,
  name,
  values,
  onChange,
  className,
  disabled,
  direction = 'vertical',
  cellWidth = 3
}) => {
  const style = { textTransform: 'none', width: '100%' };
  return (
    <Row style={{ marginTop: '8px' }}>
      <div></div>
      {options
        .filter(({ display = true }) => display)
        .map(({ value, label, disabled: optionDisable }, index) => {
          const item = (
            <FormGroup key={key + index} name={name} className={className} check>
              <Input
                type="radio"
                id={name + index}
                name={name}
                value={value}
                checked={value === values}
                onChange={e => onChange(value)}
                disabled={disabled || optionDisable}
              />
              <Label
                htmlFor={name + index}
                style={index === options.length - 1 ? { ...style, marginBottom: 0 } : style}
              >
                {label}
              </Label>
            </FormGroup>
          );
          return (
            <Col xs={'horizon' === direction ? cellWidth : 12} key={key + index}>
              {'horizon' === direction && item}
              {'vertical' === direction && (
                <Row>
                  <Col>{item}</Col>
                </Row>
              )}
            </Col>
          );
        })}
    </Row>
  );
};

RadioList.propTypes = {
  options: PropTypes.array,
  key: PropTypes.string,
  name: PropTypes.string,
  values: PropTypes.any,
  onChange: PropTypes.func,
  className: PropTypes.string,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool
};

RadioList.defaultProps = {
  options: [],
  key: ulid(),
  name: ulid(),
  values: '',
  onChange: values => console.log('onChange >>> values: ', values),
  className: '',
  defaultValue: '',
  disabled: false
};

export default RadioList;

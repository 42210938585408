import React, { useEffect, useState } from 'react';
import { Loading } from 'element-react';
import { auth as authService } from 'services';
import { useTranslation } from 'react-i18next';
import '../Login/index.scss';
import { logger } from 'core/logger';
import { cookies } from 'core/utils';
import {
  COOKIE_KEY_APP_ATM_TOKEN,
  COOKIE_KEY_APP_ATM_TOKEN_EXP,
  COOKIE_KEY_REFRESH_TOKEN,
  COOKIE_KEY_USER_TOKEN,
  STORAGE_KEY_APP_SYNC_SIGNED_IN,
  STORAGE_KEY_LAST_ACTIVE_TIME,
  PROVIDER
} from 'core/constants';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router';
import { encodeBase64, decodeBase64 } from 'core/helpers';
import { MFA_METHODS } from 'core/constants';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Callback(props) {
  const { history, setLoginIdStore } = props;
  const queryParams = useQuery();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  const handleLoginError = error => {
    let err = error;
    try {
      if (typeof error === 'string') err = JSON.parse(error);
    } catch (exErr) {
      console.error('error: ', error);
    }
    let code = err && err.code;

    logger.error('Callback -> ErrCode', code);
    let warningMessage = '';
    switch (code) {
      case 'auth/user-not-found':
      case 'user-not-found':
        warningMessage = t('common.message.user.notFound');
        break;
      case 'account-is-locked':
        warningMessage = t('common.message.user.locked');
        break;
      case 'domain-not-found':
        warningMessage = t('common.message.domain.notFound');
        break;
      case 'domain-inactive':
        warningMessage = t('common.message.domain.inactive');
        break;
      case 'method-not-allow':
        warningMessage = t('common.message.provider.invalid');
        break;
      case 'generate-otp-error': {
        warningMessage = t('mfa.message.generate.otp.error');
        break;
      }
      case 'mfaMethod-is-not-existed':
        warningMessage = t('mfa.method.not.existed');
        break;
      case 'missing-relay-state':
        warningMessage = t('saml.missing.relay.state');
        break;
      case 'invalid-relay-state':
        warningMessage = t('saml.invalid.relay.state');
        break;
      case 'nameid-not-match':
        warningMessage = t('saml.nameid.not.match');
        break;
      case 'invalid-saml-response':
        warningMessage = t('saml.invalid.saml.response');
        break;
      case 'validation-invalid':
        warningMessage = t('common.message.invalidInputs');
        break;
      case 'auth-code-invalid':
        warningMessage = t('authCode.error.invalid');
        break;
      case 'auth-code-expired':
        warningMessage = t('authCode.error.expired');
        break;
      case 'too-many-sms':
        warningMessage = t('mfa.message.too.many.sms');
        break;
      case 'country-not-support-sms':
        warningMessage = t('common.message.mobile.not.support.country');
        break;
      case 'contact-email-is-required-in-policy':
        warningMessage = t('Common.Message.ContactEmail.Required');
        break;
      case 'mfa-is-required-in-policy':
        warningMessage = t('Common.Message.MFA.Required');
        break;
      default:
        warningMessage = t('common.message.error');
        break;
    }
    setLoading(false);
    if (warningMessage) {
      toast.warning(warningMessage);
    }
    setTimeout(() => {
      history.push('/');
    }, 2500);
  };

  const loginSuccess = data => {
    const { code, userToken = '', refreshToken = '', atmToken = '', atmTokenExpiresAt = '' } = data;

    let url = null;
    let state = queryParams.get('state');
    if (state && state !== '') {
      state = decodeBase64(state);
      if (state.startsWith('redirect=')) {
        url = state.substring(9);
      } else if (state.includes('redirect')) {
        // state from Microsoft
        const data = JSON.parse(state);
        url = data['redirect'];
      }
    }
    if (code === 'otp-required') {
      const { email, mfa, mfaMethod } = data;
      const ttl = Math.floor(new Date().getTime() / 1000);
      if (MFA_METHODS.EMAIL === mfaMethod) {
        localStorage.setItem('sendEmailOtpCodeTimestamp', ttl);
      } else if (MFA_METHODS.SMS === mfaMethod) {
        localStorage.setItem('sendSmsOtpCodeTimestamp', ttl);
      }
      setLoginIdStore(email);
      localStorage.setItem(email, ttl);
      localStorage.setItem('mfa', mfa);
      localStorage.setItem('mfaMethod', mfaMethod);
      localStorage.setItem('action', 'LOGIN');
      localStorage.removeItem('mfaRetry');
      localStorage.setItem('mfaLoginTimestamp', Math.round(Date.now() / 1000));
      history.push(url ? `/mfa?redirect=${encodeBase64(url)}` : '/mfa');
    } else {
      localStorage.setItem('action', 'LOGIN');
      localStorage.setItem(STORAGE_KEY_APP_SYNC_SIGNED_IN, new Date().getTime());
      localStorage.setItem(STORAGE_KEY_LAST_ACTIVE_TIME, new Date().getTime());
      cookies.setKey(COOKIE_KEY_USER_TOKEN, userToken);
      cookies.setKey(COOKIE_KEY_REFRESH_TOKEN, refreshToken);
      cookies.setKey(COOKIE_KEY_APP_ATM_TOKEN, atmToken);
      cookies.setKey(COOKIE_KEY_APP_ATM_TOKEN_EXP, atmTokenExpiresAt);
      // Redirect
      if (url) {
        window.location.href =
          url.includes('/saml/idp/sso?') && !url.includes('&redirectSSO') ? `${url}&redirectSSO=${true}` : url;
        return;
      }
      window.location.replace('/');
    }
  };

  useEffect(() => {
    const error = queryParams.get('error');
    const authCode = queryParams.get('authCode');

    console.log('Callback info: ', { error, authCode });
    if (error) {
      return handleLoginError(error);
    }

    const code = queryParams.get('code');
    const state = queryParams.get('state');
    const sessionState = queryParams.get('session_state');
    let microsoftState = '';
    const token = queryParams.get('token');
    if (token) {
      // Sign In With Google callback from Passwordless
      authService
        .authenticate({ token: token, provider: PROVIDER.GOOGLE })
        .then(loginSuccess)
        .catch(error => {
          console.log('signInGoogle.error: ', error);
          if (error.response && error.response.data && error.response.data.code) {
            handleLoginError(error.response.data);
          } else {
            handleLoginError({ code: error.error });
          }
        });
    } else if (code && state) {
      // Sign In With Google|Microsoft callback
      const provider = sessionState ? PROVIDER.MICROSOFT : PROVIDER.GOOGLE;
      if (provider === PROVIDER.MICROSOFT) {
        const data = JSON.parse(decodeBase64(state));
        microsoftState = data['state'];
      }

      authService
        .authenticate({ authCode: code, state: microsoftState, provider })
        .then(loginSuccess)
        .catch(error => {
          console.log('signInGoogle.error: ', error);
          if (error.response && error.response.data && error.response.data.code) {
            handleLoginError(error.response.data);
          } else {
            handleLoginError({ code: error.error });
          }
        });
    } else {
      authService
        .verifyCode(authCode, state)
        .then(data => {
          loginSuccess(data);
        })
        .catch(error => {
          console.log('verifyCode.error: ', error);
          if (error.response && error.response.data && error.response.data.code) {
            handleLoginError(error.response.data);
          } else {
            handleLoginError({ code: error.error });
          }
        });
    }
  }, []);

  return loading && <Loading fullscreen={true} />;
}

export default Callback;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { InputGroup, InputGroupText, InputGroupAddon, Input } from 'reactstrap';
import { pick } from 'lodash';
import i18n from 'i18n';
import './index.scss';
import { EMOJI_REGEX, regexpPassword } from 'core/constants';

const checkPassStrength = (password, thresholdLength) => {
  if (!password) return 0;
  const contains_lowercase = /[a-z]/.test(password);
  const contains_number = /\d/.test(password);
  const contains_uppercase = /[A-Z]/.test(password);
  const contains_long = password.length >= thresholdLength;
  const defineRegex = "[!@#$%^&*()_+\\-={}|\\[\\]']";
  const regexp = new RegExp(defineRegex, 'gi');
  const contains_special = regexp.test(password);
  const contains_HTMLTag = !isHTML(password);
  return (
    Number(contains_lowercase) +
    Number(contains_number) +
    Number(contains_uppercase) +
    Number(contains_long) +
    Number(contains_special) +
    Number(contains_HTMLTag)
  );
};

const isHTML = str => {
  let a = document.createElement('div');
  a.innerHTML = str;

  for (let c = a.childNodes, i = c.length; i--; ) {
    if (c[i].nodeType === 1) return true;
  }

  return false;
};

const errors = {
  isRequired: { keyMessage: 'message.pleaseEnter', field: 'label.newPassword' },
  isIncorrectFormat: 'changePassword.message.password.wrongFormat'
};

export default class BaseInputPassword extends PureComponent {
  thresholdLength = 10;

  static propTypes = {
    name: PropTypes.string,
    placeholder: PropTypes.string,
    hint: PropTypes.string,
    maxLength: PropTypes.number,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    append: PropTypes.any,
    prepend: PropTypes.any,
    showEyeIcon: PropTypes.bool,
    showStrength: PropTypes.bool,
    onChange: PropTypes.func,
    convertToHalfWidth: PropTypes.bool
  };
  static defaultProps = {
    showStrength: true,
    showEyeIcon: true,
    convertToHalfWidth: true,
    hint: 'changePassword.password.hint.text',
    onChange: () => {}
  };
  constructor(props) {
    super(props);
    this.state = { strength: null };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({
        strength: checkPassStrength(nextProps.value, this.thresholdLength)
      });
    }
  }

  onChangePassword(event) {
    let password = event.target.value;
    // Convert full-width to half-width
    if (!regexpPassword.test(password)) {
      return;
    }
    // replace emoji
    password = password.replace(EMOJI_REGEX, '');
    let strength = checkPassStrength(password, this.thresholdLength);
    let errorMessage = '';
    if (password.length === 0) {
      errorMessage = errors.isRequired;
    } else if (password.length < this.thresholdLength || strength < 6) {
      errorMessage = errors.isIncorrectFormat;
    }
    // Substring
    if (this.props.maxLength) {
      password = password.substr(0, this.props.maxLength);
    }
    this.props.onChange(password, errorMessage);
    this.setState({ strength });
  }

  render() {
    // variables
    const { showPassword, strength } = this.state;
    const { append, prepend, hint, showStrength, showEyeIcon } = this.props;
    const type = showPassword ? 'text' : 'password';
    const inputProps = {
      ...pick(this.props, ['name', 'placeholder', 'value', 'disabled', 'readOnly']),
      type
    };
    const passwordLength = inputProps.value ? inputProps.value.length : 0;
    const passwordLong = passwordLength >= this.thresholdLength;
    const passwordStrong = passwordLength > this.thresholdLength;
    const strengthClass = ['strength-meter mt-2', passwordLength > 0 ? 'visible' : 'visible'].join(' ').trim();
    const counterClass = [
      'badge badge-pill',
      passwordLong ? (passwordStrong ? 'badge-success' : 'badge-warning') : 'badge-danger'
    ]
      .join(' ')
      .trim();

    return (
      <div className="change-password-container">
        {showStrength && <span className="form-hint">{hint ? i18n.t(hint) : ''}</span>}
        {/** Render the password strength meter **/}
        {showStrength && (
          <div className={strengthClass}>
            <div className="strength-meter-fill" data-strength={strength}></div>
          </div>
        )}
        <InputGroup>
          {prepend && (
            <InputGroupAddon addonType="prepend">
              <InputGroupText>{prepend}</InputGroupText>
            </InputGroupAddon>
          )}
          <Input {...inputProps} onChange={this.onChangePassword.bind(this)} />
          {showStrength && (
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <div className={'password-length'}>
                  <span className={counterClass}>
                    {passwordLength
                      ? passwordLong
                        ? passwordStrong
                          ? `${this.thresholdLength}+`
                          : this.thresholdLength
                        : passwordLength
                      : ''}
                  </span>
                </div>
              </InputGroupText>
            </InputGroupAddon>
          )}
          {showEyeIcon && (
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <i
                  className="material-icons"
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    this.setState({
                      showPassword: !showPassword
                    })
                  }
                >
                  {type === 'text' ? 'visibility_off' : 'visibility'}
                </i>
              </InputGroupText>
            </InputGroupAddon>
          )}
          {append && (
            <InputGroupAddon addonType="append">
              <InputGroupText>{append}</InputGroupText>
            </InputGroupAddon>
          )}
        </InputGroup>
      </div>
    );
  }
}

import React from 'react';
import { useTranslation } from 'react-i18next';

function PageNotFound(props) {
  const { t } = useTranslation();
  return (
    <div className="row">
      <div className="col-12">
        <div className="container py-5">
          <div className="row text-center">
            <div className="col-md-12">
              <p>
                <i style={{ fontSize: '90px', color: '#ffa500c7' }} className="material-icons">
                  warning
                </i>
              </p>
              <h3 className="page-not-found-title">{t('page.not.found.title')}</h3>
              <p className="page-not-found-description"> {t('page.not.found.hint')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageNotFound;

import { connect } from 'react-redux';
import view from 'pages/TermsOfUse/view';
import { updateFullLoading, selectFullLoading } from 'store/loadingSlice';
import { setLoginIdStore } from 'store/globalSlice';

const mapState = state => {
  return {
    fullloading: selectFullLoading(state)
  };
};

const mapDispatch = { updateFullLoading, setLoginIdStore };

export default connect(mapState, mapDispatch)(view);

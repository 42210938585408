import { connect } from 'react-redux';
import { selectFullLoading, updateFullLoading } from 'store/loadingSlice';
import { updateNotification } from 'store/notificationSlice';
import { updateUser } from 'store/userSlice';
import view from './view';

const mapState = state => {
  return {
    fullloading: selectFullLoading(state)
  };
};

const mapDispatch = {
  updateFullLoading: updateFullLoading,
  updateNotification: updateNotification,
  updateUser
};

export default connect(mapState, mapDispatch)(view);

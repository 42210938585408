import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import { user as userService } from 'services';
import { errorUtils } from 'core/utils';
import { useHistory } from 'react-router';

const PasswordlessRegistration = ({ user, updateNotification, updateUser }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const onRegister = async () => {
    if (!window.flutter_inappwebview) return;

    try {
      await userService.getUser();
    } catch (err) {
      errorUtils.handleError(err, '', updateNotification, updateUser, history, t);
      return;
    }
    window.flutter_inappwebview.callHandler('onRegister', user.id, user.loginId);
  };

  return (
    <div className="row justify-content-center">
      <div
        className="col-12 col-lg-9 xs-m-l-r-0"
        style={{
          marginTop: '10px',
          // marginLeft: '30px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Row
          style={{ width: 500, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          className="m-w-100 m-0"
        >
          <Col md={9} className="text-center">
            {/* <img src={qrSample} width="100%" alt={t('qr.sample')} /> */}
            <p className="text-color-sub">{t('profile.passwordless.registration.hint')}</p>
            <button
              className="btn btn-info btn-cons m-t-12 btn-block"
              style={{ borderRadius: '15px' }}
              type="button"
              onClick={onRegister}
            >
              {t('profile.passwordless.registration.button.setup')}
            </button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

PasswordlessRegistration.propTypes = {
  user: PropTypes.any
};

export default PasswordlessRegistration;

/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'log',
  initialState: {
    logs: []
  },
  reducers: {
    addLog(state, { payload: logs }) {
      state.logs = logs;
    }
  }
});

export default slice.reducer;

export const getLogs = state => state.log.logs;
export const { addLog } = slice.actions;

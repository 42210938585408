import React from 'react';
import Types from 'prop-types';
import Table from 'components/BaseTable';
import MoreTextWithToolTip from 'components/MoreTextWithToolTip';
import { formatDateTime } from 'core/utils';
class LogList extends React.Component {
  static propTypes = {
    logs: Types.array,
    searchText: Types.string,
    initialized: Types.bool,
    t: Types.any,
    timezone: Types.string
  };

  static defaultProps = {
    logs: []
  };

  getColumns = () => {
    const { t, timezone } = this.props;
    return [
      {
        Header: t('common.label.number'),
        width: 80,
        sortable: false,
        Cell: row => <MoreTextWithToolTip className="justify-content-end">{row.viewIndex + 1}</MoreTextWithToolTip>
      },
      {
        Header: t('log.label.signedIn.date'),
        accessor: 'createdAt',
        minWidth: 200,
        Cell: row => {
          const dates = formatDateTime.dateSecond(row.value, timezone).split(' ');
          return (
            <MoreTextWithToolTip className="justify-content-center">
              {dates[0]} {dates[1]} {dates[2]}
            </MoreTextWithToolTip>
          );
        }
      },
      {
        Header: t('log.label.sourceIP'),
        accessor: 'sourceIp',
        width: 250,
        sortMethod: (firstValue, secondValue) => {
          if (firstValue && secondValue) {
            const partFirstIp = firstValue.split('.').map(num => parseInt(num));
            const partSecondIp = secondValue.split('.').map(num => parseInt(num));
            let result = null;
            const compare = (i = 0) => {
              if (i < 4) {
                if (partFirstIp[i] === partSecondIp[i]) {
                  i++;
                  compare(i);
                } else {
                  result = partFirstIp[i] > partSecondIp[i] ? 1 : -1;
                }
                return result;
              } else {
                return 1;
              }
            };
            if (partFirstIp.length === partSecondIp.length && partSecondIp.length === 4) {
              return compare();
            }
            return 1;
          }
          return 1;
        },
        Cell: row => row.value
      },
      {
        Header: t('log.label.country'),
        accessor: 'country',
        minWidth: 100,
        sortable: true,
        Cell: row => <MoreTextWithToolTip>{row.value}</MoreTextWithToolTip>
      },
      {
        Header: t('log.label.device'),
        accessor: 'device',
        minWidth: 100,
        sortable: true,
        Cell: row => <MoreTextWithToolTip>{row.value}</MoreTextWithToolTip>
      },
      {
        Header: t('log.label.user.agent'),
        accessor: 'userAgent',
        minWidth: 200,
        sortable: true,
        Cell: row => {
          return <MoreTextWithToolTip>{row.value}</MoreTextWithToolTip>;
        }
      }
    ];
  };

  render() {
    let columns = this.getColumns();
    return (
      <Table
        columns={columns}
        initialized={this.props.initialized}
        data={this.props.logs}
        showPagination={false}
        sortedRules={[
          {
            id: 'createdAt',
            desc: true
          }
        ]}
      />
    );
  }
}

export default LogList;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import { withTranslation } from 'react-i18next';

class PopoverInfo extends Component {
  static propTypes = {
    t: PropTypes.any,
    target: PropTypes.string,
    toggle: PropTypes.func,
    header: PropTypes.string,
    content: PropTypes.string,
    placement: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <UncontrolledPopover
        className="popover-info"
        placement={this.props.placement}
        trigger="hover"
        target={this.props.target}
      >
        <PopoverHeader>{this.props.header}</PopoverHeader>
        <PopoverBody>{this.props.content}</PopoverBody>
      </UncontrolledPopover>
    );
  }
}

export default withTranslation()(PopoverInfo);

import { connect } from 'react-redux';
import view from 'pages/ProfileSetting/forms/MFA/view';
import { updateNotification, selectNotification } from 'store/notificationSlice';
import { updateFullLoading, selectFullLoading } from 'store/loadingSlice';
import { getLoginIdStore } from 'store/globalSlice';
import { updateUser } from 'store/userSlice';
import { addResources } from 'store/resourceSlice';

const mapState = state => {
  return {
    notification: { ...selectNotification(state) },
    fullloading: selectFullLoading(state),
    loginIdStore: getLoginIdStore(state)
  };
};

const mapDispatch = { updateNotification, updateFullLoading, updateUser, addResources };

export default connect(mapState, mapDispatch)(view);

import { connect } from 'react-redux';
import view from 'pages/Passwordless/view';

import { selectFullLoading } from 'store/loadingSlice';
import { updateFullLoading } from 'store/loadingSlice';

const mapState = state => {
  return {
    fullloading: selectFullLoading(state)
  };
};

const mapDispatch = {
  updateFullLoading
};

export default connect(mapState, mapDispatch, null, { forwardRef: true })(view);

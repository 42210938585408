import { connect } from 'react-redux';
import view from 'pages/ProfileSetting/forms/Profile/view';
import { updateNotification, selectNotification } from 'store/notificationSlice';
import { updateFullLoading, selectFullLoading } from 'store/loadingSlice';
import { updateUser, getUser } from 'store/userSlice';
import { setLoginIdStore } from 'store/globalSlice';

const mapState = state => {
  return {
    notification: { ...selectNotification(state) },
    fullloading: selectFullLoading(state),
    currentUser: getUser(state)
  };
};

const mapDispatch = { updateNotification, updateFullLoading, updateUser, setLoginIdStore };

export default connect(mapState, mapDispatch)(view);

import { request } from 'core/request';

const getSections = async () => {
  const data = await request({
    method: 'get',
    url: 'dashboard/sections'
  });
  return data;
};

const getSectionById = async sectionId => {
  const data = await request({
    method: 'get',
    url: `dashboard/sections/${sectionId}/resources`
  });
  return data;
};

const addSection = async section => {
  const data = await request({
    method: 'post',
    url: 'dashboard/sections',
    data: section
  });
  return data;
};

const updateSection = async section => {
  const data = await request({
    method: 'put',
    url: 'dashboard/sections',
    data: section
  });
  return data;
};

const deleteSection = async sectionId => {
  const data = await request({
    method: 'delete',
    url: `dashboard/sections/${sectionId}`
  });
  return data;
};
export default {
  getSections,
  getSectionById,
  addSection,
  updateSection,
  deleteSection
};

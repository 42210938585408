/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'loading',
  initialState: {
    fullloading: false
  },
  reducers: {
    updateFullLoading(state, { payload: fullloading }) {
      state.fullloading = fullloading;
    }
  }
});

export const { updateFullLoading } = slice.actions;
export default slice.reducer;

export const selectFullLoading = state => state.loading.fullloading;

import React, { PureComponent } from 'react';
import ReactSelect, { createFilter } from 'react-select';
import PropTypes from 'prop-types';
import './index.scss';
import i18n from 'i18n';

const selectStyles = {
  menuPortal: base => ({ ...base, zIndex: 1048 }),
  multiValueLabel: base => ({
    ...base,
    color: '#FF5722',
    marginLeft: '5px',
    marginRight: '5px'
  }),
  multiValueRemove: (base, state) => {
    return state.data.preventDelete ? { ...base, display: 'none' } : base;
  }
};

export default class BaseSelect extends PureComponent {
  static propTypes = {
    name: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    options: PropTypes.array,
    ignores: PropTypes.array,
    multiple: PropTypes.bool,
    disabled: PropTypes.bool,
    clearable: PropTypes.bool,
    sortType: PropTypes.string,
    allowSort: PropTypes.bool,
    allowSearch: PropTypes.bool,
    styles: PropTypes.object,
    onChange: PropTypes.func,
    disableOptionsFunc: PropTypes.func
  };

  static defaultProps = {
    options: [],
    ignores: [],
    sortType: 'asc',
    allowSort: true,
    allowSearch: true,
    clearable: false,
    styles: {},
    onChange: () => {},
    disableOptionsFunc: () => {}
  };

  sortByAlphabet(source, field, sortType, allowSort) {
    if (!allowSort) {
      return source;
    }
    return source.sort(function(a, b) {
      let x = a[field].toLowerCase();
      let y = b[field].toLowerCase();
      let res = true;
      if ('asc' === sortType) {
        res = x < y ? -1 : x > y ? 1 : 0;
      } else if ('desc' === sortType) {
        res = !(x < y ? -1 : x > y ? 1 : 0);
      }
      return res;
    });
  }

  render() {
    const {
      name,
      placeholder,
      value,
      options = [],
      ignores = [],
      multiple,
      clearable,
      disabled,
      sortType,
      allowSort,
      allowSearch,
      styles,
      disableOptionsFunc
    } = this.props;
    let valueSelect = value;
    // When options is empty then change value to empty
    let functionChange = this.props.onChange;
    // Convert value
    let convertedValues = options.filter(option => {
      const { value } = option;
      return Array.isArray(valueSelect) ? valueSelect.includes(value) : valueSelect === value;
    });
    // Filter options
    let convertedOptions = options;
    if (ignores.length > 0) {
      const ignoresArr = ignores.map(item => item.value);
      convertedOptions = options.filter(({ value }) => !ignoresArr.includes(value));
    }
    // Sort finally options
    convertedOptions = this.sortByAlphabet(convertedOptions, 'label', sortType, allowSort);
    return (
      <ReactSelect
        name={name}
        placeholder={<p>{placeholder}</p>}
        value={convertedValues}
        options={convertedOptions}
        isOptionDisabled={disableOptionsFunc}
        onChange={res => {
          if (multiple) {
            functionChange(res ? res.map(({ value }) => value) : []);
          } else {
            functionChange(res ? res.value : null);
          }
        }}
        filterOption={createFilter({
          matchFrom: 'any',
          stringify: option => `${option.label}`
        })}
        noOptionsMessage={() => i18n.t('common.label.no.option')}
        closeMenuOnSelect={!multiple}
        isSearchable={allowSearch}
        isClearable={clearable}
        isMulti={multiple}
        isDisabled={disabled}
        styles={{ ...selectStyles, ...styles }}
        classNamePrefix="react-select"
        menuPortalTarget={document.body}
        menuPlacement={'auto'}
        theme={theme => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: '#f8f8f8', // background options
            primary50: '#CCCCCC', // background select option
            primary: '#f8f8f8' // background selected option
          }
        })}
      />
    );
  }
}

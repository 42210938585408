import { connect } from 'react-redux';
import view from 'pages/ChangePassword/view';
import { updateNotification, selectNotification } from 'store/notificationSlice';
import { updateUser, getUser } from 'store/userSlice';
import { updateFullLoading, selectFullLoading } from 'store/loadingSlice';
import { getModeLogin } from 'store/globalSlice';

const mapState = state => {
  return {
    notification: { ...selectNotification(state) },
    fullloading: selectFullLoading(state),
    currentUser: getUser(state),
    modeLogin: getModeLogin(state)
  };
};

const mapDispatch = {
  updateNotification: updateNotification,
  updateUser: updateUser,
  updateFullLoading: updateFullLoading
};

export default connect(mapState, mapDispatch)(view);

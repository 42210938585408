import { connect } from 'react-redux';
import view from 'pages/SSO/view';
import { updateFullLoading, selectFullLoading } from 'store/loadingSlice';
import { updateNotification, selectNotification } from 'store/notificationSlice';
import { getModeLogin, setModeLogin, setLoginIdStore } from 'store/globalSlice';

const mapState = state => {
  return {
    fullloading: selectFullLoading(state),
    notification: { ...selectNotification(state) },
    modeLogin: getModeLogin(state)
  };
};

const mapDispatch = {
  updateFullLoading: updateFullLoading,
  updateNotification: updateNotification,
  setLoginIdStore,
  setModeLogin
};

export default connect(mapState, mapDispatch)(view);
